@font-face {
  font-family: 'OpenSans';
  src: url('../public/fonts/OpenSans-Regular.ttf');
}

@property --bgColor1 {
  syntax: '<color>';
  initial-value: #46d1ff;
  inherits: false;
}

@property --bgColor2 {
  syntax: '<color>';
  initial-value: #e3f8ff;
  inherits: false;
}

body, html, #root, #r3f-wrapper {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'OpenSans', serif;
}

#r3f-wrapper {
  background: linear-gradient(var(--bgColor1), var(--bgColor2));
  transition: --bgColor1 1s, --bgColor2 1s;
}

.morning-background {
  --bgColor1: #46d1ff;
  --bgColor2: #e3f8ff;
}

.daylight-background {
  --bgColor1: #FFD1E3;
  --bgColor2: #7EA1FF;
}

.afternoon-background {
  --bgColor1: #FB6D48;
  --bgColor2: #D74B76;
}

.night-background {
  --bgColor1: #074173;
  --bgColor2: #1679AB;
}

.content {
  transform: translate3d(calc(50% + 0px), 0, 0);
  text-align: left;
  background: #202035;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  pointer-events: none;
  position: fixed;
  top: 25%;
  left: 51%;
  z-index: 10;
  width: 260px;
  font-family: "OpenSans", serif;
  letter-spacing: 0.025em;
  font-size: 10px;
}

.content::before {
  content: "";
  position: absolute;
  top: 25px;
  left: -40px;
  height: 1px;
  width: 40px;
  background: black;
}

.hidden {
  visibility: hidden;
}

.guide-information {
  position: absolute;
  padding: 20px;
  font-size: 14px;
}

.brand-information {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-bottom: 20px;
  z-index: 10;
  font-size: 14px;
  text-align: center;
}

.action-link {
  text-decoration: none;
  color: #38b6ff;
  cursor: pointer;
  font-weight: bold;
}

.credits-wrapper {
  padding: 20px;
}

.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: inherit;
  background-image: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 1s;
  font-family: "OpenSans", serif;
}

.loadingScreen--started {
  opacity: 0;
  pointer-events: none;
}

.loadingScreen--started .loadingScreen__board {
  opacity: 0;
}

.loadingLabel{
  color: white;
  margin-top: 30px;
  font-size: 30px;
  text-align: center;
  padding: 10px;
}

.loadingButton {
  margin-top: 30px;
  font-family: "OpenSans", serif;
  background: none;
  color: white;
  border: 0px;
  font-size: 30px;
  padding: 10px;
  transition-duration: 100ms;
  cursor: pointer;
}

.loadingButton:hover{
  color: #38b6ff;
}

.clock-wrapper {
  position: relative;
  width: 120px;
  height: 120px;
}

.clock{
	border-radius: 70px;
	border: 3px solid #fff;
	position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  display: block;
  width: 120px;
  height: 120px;
}

.clock:after{
	content: "";
	position: absolute;
	background-color: #fff;
	top: 13px;
	left: 48%;
	height: 50px;
	width: 4px;
	border-radius: 5px;
	-webkit-transform-origin: 50% 97%;
  transform-origin: 50% 97%;
	-webkit-animation: grdAiguille 2s linear infinite;
  animation: grdAiguille 2s linear infinite;
}

.clock:before{
	content: "";
	position: absolute;
	background-color: #fff;
	top: 24px;
	left: 48%;
	height: 40px;
	width: 4px;
	border-radius: 5px;
	-webkit-transform-origin: 50% 94%;
  transform-origin: 50% 94%;
	-webkit-animation: ptAiguille 12s linear infinite;
  animation: ptAiguille 12s linear infinite;
}

@-webkit-keyframes grdAiguille{
  0%{-webkit-transform:rotate(0deg);}
  100%{-webkit-transform:rotate(360deg);}
}

@keyframes grdAiguille{
  0%{transform:rotate(0deg);}
  100%{transform:rotate(360deg);}
}

@-webkit-keyframes ptAiguille{
  0%{-webkit-transform:rotate(0deg);}
  100%{-webkit-transform:rotate(360deg);}
}

@keyframes ptAiguille{
  0%{transform:rotate(0deg);}
  100%{transform:rotate(360deg);}
}

/* Responsive Media */
/* Tablet */
@media (max-width: 1023px) {
	.content {
    left: 50%;
    width: 200px;
    font-size: 8px;
  }

  .content::before {
    left: -20px;
    width: 20px;
  }

  .loadingLabel{
    font-size: 26px;
  }
  
  .loadingButton {
    font-size: 26px;
  }
}

/* Phone */
@media (max-width: 767px) {
	.guide-information {
    font-size: 10px;
  }

  .content {
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0px;
    width: 160px;
    font-size: 8px;
  }

  .content::before {
    left: 0px;
    width: 0px;
    margin: 0px;
    padding: 0px;
  }

  .loadingLabel{
    font-size: 16px;
  }
  
  .loadingButton {
    font-size: 16px;
  }
}
/* ./Responsive Media */
